import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, TextField } from "@mui/material";

import Button from "@commons/Button";
import LanguageList from "@commons/LanguageList";
import { useToast } from "@commons/ToastProvider";
import VoiceSelector from "@commons/VoiceSelector";
import { DEFAULT_INPUT_LANGUAGE } from "@utils/Constants";
import { generateUUID } from "@helpers";
import SpeakerWapper from "@speaker";
import { useTranslation } from "react-i18next";

function LanguageSelector() {
  const [code, selectedCode] = useState(DEFAULT_INPUT_LANGUAGE);
  const [voice, setVoice] = useState("male");
  const navigate = useNavigate();
  const showToast = useToast();
  const { t } = useTranslation();

  const handleLanguageChange = ({ target: { value } }) => {
    console.log(value);
    selectedCode(value);
  };

  const handleVoiceChange = ({ target: { value } }) => {
    console.log("voice: ", voice);
    setVoice(value);
  };

  const startRecording = () => {
    if (!code) {
      showToast(t("languageSelectToastText"));
      return;
    }
    const uuid = generateUUID();
    navigate("/speaker/recorder/" + code + "/" + voice + "/" + uuid);
  };

  return (
    <SpeakerWapper title={t("adminPortalText")}>
      <LanguageList
        onChange={handleLanguageChange}
        selected={code}
        type="dropdown"
      />
      <VoiceSelector voice={voice} onChange={handleVoiceChange} />
      <Box
        mt="10px"
        width="auto"
        sx={{
          "& .MuiInputBase-root": {
            height: "100%",
          },
        }}
      >
        <TextField
          // variant="outlined"
          // fullWidth
          placeholder={t("Glossary")}
          onBlur={(e) => {
            const wordList = e.target.value
              .split(",")
              .map((word) => word.trim());
            localStorage.setItem("glossary", JSON.stringify(wordList));
            // sio.emit("glossary", wordList);
          }}
          sx={{
            marginBottom: "10px",
            background: "#1e1e1e",
            color: "#fff",
            height: "100px !important",
            borderRadius: "12px",
          }}
        />
      </Box>

      <Box mt="10px">
        <Button sx={{ width: "200px" }} onClick={startRecording}>
          {t("startSpeaking")}
        </Button>
      </Box>
    </SpeakerWapper>
  );
}

export default LanguageSelector;
