/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import PCMPlayer from "pcm-player";
import { io } from "socket.io-client";

import { DEFAULT_AUDIO_VOLUME, OUTPUT_SAMPLE_RATE } from "@utils/Constants";
import Transcription from "@listener-screens/Transcription";
import { convertPCMToAudioBuffer, windowNavigate } from "@helpers";
import { LANGUAGES } from "@utils/Constants";

function ListeningRoom() {
  const [languages, setLanguages] = useState({ input: "", output: "" });
  const [loading, setLoading] = useState(false);
  const [volume, setVolume] = useState({
    original: DEFAULT_AUDIO_VOLUME,
    translation: DEFAULT_AUDIO_VOLUME,
  });
  const [translation, setTranslation] = useState("");
  const { languages: socketLanguages, id: socketId } = useParams();
  const player = useRef(null);
  const originalPlayer = useRef(null);
  const socketRef = useRef(null);
  const changeRoomRef = useRef(false);
  const outputLanguageForSwitch = useRef("");
  const inputLanguageForSwitch = useRef("");
  // console.log("socketRef: ", socketRef);

  useEffect(() => {
    const [speakerLanguage, listenerLanguage] = socketLanguages.split("-");
    setLanguages(() => ({ input: speakerLanguage, output: listenerLanguage }));
    //connect to socket here
    connectSocket(speakerLanguage, listenerLanguage);
    setupPlayerAudio();
  }, [socketLanguages, socketId]);

  useEffect(() => {
    return () => {
      socketRef.current?.disconnect(); // Cleanup on unmount
    };
  }, []);

  useEffect(() => {
    if (player.current) {
      player.current.volume(volume.translation);
    }
    if (originalPlayer.current) {
      originalPlayer.current.volume(volume.original);
    }
  }, [volume]);

  const setupPlayerAudio = () => {
    player.current = new PCMPlayer({
      inputCodec: "Int16",
      channels: 1,
      sampleRate: OUTPUT_SAMPLE_RATE,
      flushTime: 0,
      volume: volume.translation,
    });
    originalPlayer.current = new PCMPlayer({
      inputCodec: "Int16",
      channels: 1,
      sampleRate: OUTPUT_SAMPLE_RATE,
      flushTime: 0,
      volume: volume.original,
    });
  };

  const connectSocket = (inputLang, outputLang) => {
    setLoading(true);
    socketRef.current = io(process.env.REACT_APP_SOCKET_URL, {
      path: "/ws/socket.io",
      transports: ["websocket"],
      query: {
        clientID: socketId,
      },
    });
    // socketRef.current.connect();
    socketRef.current.on("connect", () => {
      console.log("Connected to WebSocket server");
      //   updateLanguages();
      //   startRecording();
      setLoading(false);
      socketRef.current.on("listener_joined_speaker", (data) => {
        console.log("data: ", data);
        const isNotLanguage =
          LANGUAGES.filter((lang) => lang.code === data.language.split("-")[0])
            .length < 1;
        console.log("isNotLanguage: ", isNotLanguage);
        if (changeRoomRef.current && isNotLanguage) {
          console.log("data: ", data);
          windowNavigate(
            "/listener/room/" +
              inputLanguageForSwitch.current +
              "-" +
              outputLanguageForSwitch.current +
              "/" +
              data.language
          );
          changeRoomRef.current = false;
        }
      });
      socketRef.current.on("translation_text", (data) => {
        const text = data.payload || data;
        setTranslation((prev) => (!prev ? text : prev + " " + text));
        console.log("text: ", text);
      });
      socketRef.current.on("translation_speech", async (data) => {
        try {
          const audioData = data.payload;
          const type = data.type;
          console.log("type: ", type);
          const buff = convertPCMToAudioBuffer(audioData);
          if (type === "short") {
            player.current.feed(buff);
          } else {
            originalPlayer.current.feed(buff);
          }
        } catch (error) {
          console.log("error: ", error);
          console.error("Received data is not an AudioBuffer.");
        }
      });
      // socketRef.current.on("listener_joined_speaker");
    });

    // socketRef.current.on("eventForClient", (data) => {
    //   console.log("Received data:", data); // Debug log to verify reception
    // });

    socketRef.current.on("connect_error", (error) => {
      console.error("Connection error:", error);
    });
  };

  const emitData = async (value) => {
    changeRoomRef.current = true;
    inputLanguageForSwitch.current = languages.input;
    outputLanguageForSwitch.current = value;
    setLoading(true);
    await new Promise((res) => setTimeout(() => res(), 1000));
    socketRef.current.emit("listener_joined", {
      language: socketLanguages.split("-")[1] + "-" + value,
      client_id: socketId,
    });
  };

  const redirectToHome = () => {
    windowNavigate("/listener/setup/" + languages.input + "/" + socketId);
  };

  const handleStopListening = async (rating) => {
    // emit feedback rating and go to setup page
    await socketRef.current.emit("incoming_analytics", {
      feedbacks: [rating],
    });
    socketRef.current.disconnect();
    redirectToHome();
  };

  return (
    <Transcription
      transcription={translation}
      language={languages.output}
      setLanguage={setLanguages}
      volume={volume}
      setVolume={setVolume}
      emitData={emitData}
      loading={loading}
      handleStopListening={handleStopListening}
      redirectToHome={redirectToHome}
    />
  );
}

export default ListeningRoom;
