import React, { useState } from "react";
import { Box, Modal, Typography, Rating, Stack } from "@mui/material";
import Button  from "@commons/Button";
import {useToast} from '@commons/ToastProvider'
import { useTranslation } from "react-i18next";

const FeedbackModal = ({ open, handleClose, handleFeedBack }) => {
  const [rating, setRating] = useState(0);
const toast = useToast()
const {t} = useTranslation();
  const handleSendFeedback = () => {
    console.log(`Rating sent: ${rating}`);
    if(rating < 1){
      toast(t('ratingWarning'), 'warning');
      return;
    }
    handleFeedBack(rating);
  };

  const handleLater = () => {
    handleClose(); // Close the modal if the user wants to send feedback later
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="feedback-modal-title"
      aria-describedby="feedback-modal-description"
    >
      <Box
        sx={(theme) => ({
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "70%", sm: "400px" }, // Responsive width
          bgcolor: theme.palette.secondary.main,
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
        })}
      >
        <Typography id="feedback-modal-title" variant="text" textAlign="center" component={'div'}>
          {t("feedbackText")}
        </Typography>
        <Stack spacing={2} alignItems="center" sx={{ mt: 4 }}>
          <Rating
            sx={(theme) => ({
              "& .MuiRating-icon": {
                color: theme.palette.text.primary,
              },
              "& .MuiRating-iconFilled": {
                color: "#faaf00",
              },
            })}
            name="feedback-rating"
            value={rating}
            onChange={(_, newValue) => setRating(newValue)}
            size="medium"
          />
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          sx={{ mt: 4 }}
        >
          <Button onClick={handleLater}>{t('later')}</Button>
          <Button onClick={handleSendFeedback}>{t('send')}</Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default FeedbackModal;
