import React from "react";
import { Box } from "@mui/material";

import ShareButton from "./ShareButton";
import UserAvatarDropdown from "./Avatar";

const SpeakerMenu = ({ share, handleShare }) => {
  return (
    <Box
      sx={{
        position: "absolute",
        right: { xs: "35px", sm: "50px" },
        top: share ? "1.25rem" : "1.9375rem",
        display: "flex",
        alignItems: "center",
        gap: "10px",
      }}
    >
      {!!share && <ShareButton handleShare={handleShare} />}
      <UserAvatarDropdown />
    </Box>
  );
};

export default SpeakerMenu;
