import React, { useState } from "react";
import {
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Box,
  Divider,
  Typography,
} from "@mui/material";
import { Logout } from "@mui/icons-material";
import useAuth from "@hooks/useAuth";
import { useTranslation } from "react-i18next";

const UserAvatarDropdown = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const { t } = useTranslation();

  const { user, logout } = useAuth();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    handleClose();
  };

  return (
    <Box style={{ display: "flex", alignItems: "center" }}>
      {!!user?.photo && (
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2, height: "18px", width: "18px" }}
        >
          <Avatar alt="User Avatar" src={user?.photo} />
        </IconButton>
      )}

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        sx={(theme) => ({
          "& .MuiMenu-paper": {
            background: theme.palette.secondary.main,
            marginTop: "20px",
            borderRadius: "12px",
          },
        })}
      >
        <MenuItem sx={{ fontSize: ".875rem" }}>
          <Typography>
            {t("hi")}, {user?.name?.split(" ")?.[0]}
          </Typography>
        </MenuItem>
        <Divider sx={{ bgcolor: "hsla(0,0%,100%,.15)" }} />
        <MenuItem
          onClick={handleLogout}
          sx={{ fontSize: ".875rem", ":hover": { bgcolor: "#2f2f2f" } }}
        >
          <Logout fontSize="small" sx={{ mr: 1 }} />
          {t("logout")}
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default UserAvatarDropdown;
