import React from "react";
import { CircularProgress, Button as MuiButton } from "@mui/material";

 const Button = ({
  children,
  isLoading,
  onClick = () => {},
  ...rest
}) => {
  return (
    <MuiButton onClick={onClick} {...rest}>
      {!isLoading ? children : <CircularProgress sx={{ color: "#000" }} />}
    </MuiButton>
  );
};


export default Button;