import React from "react";
import { Box, Typography } from "@mui/material";

import logo from "@assets/logo.svg";

function ListenerWrapper({ children, title = "" }) {
  return (
    <>
      <Box
        sx={{
          position: "absolute",
          left: { xs: "35px", sm: "50px" },
          top: { xs: "10px", sm: "20px" },
        }}
      >
        <Box
          component="img"
          sx={{
            height: { xs: "60px", sm: "85px" },
            width: { xs: "60px", sm: "85px" },
          }}
          src={logo}
          alt="logo"
        />
      </Box>
      {!!title && (
        <Box mt="30px">
          <Typography variant="title" sx={{ fontSize: "30px" }}>
            {title}
          </Typography>
        </Box>
      )}
      <Box
        mt="30px"
        sx={{
          width: {
            xs: "80%",
          },
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        {children}
      </Box>
    </>
  );
}

export default ListenerWrapper;
