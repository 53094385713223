import React from "react";
import { useNavigate, useParams } from "react-router-dom";

export const generateUUID = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const random = (Math.random() * 16) | 0;
    const value = c === "x" ? random : (random & 0x3) | 0x8;
    return value.toString(16);
  });
};

export const copyText = async (value, toast) => {
  navigator.clipboard.writeText(value).then(() => {
    toast("Link copied to the clipboard!", "success");
  });
};

export const SetupRedirect = () => {
  const { language, id } = useParams();
  const navigate = useNavigate();

  // Redirect using useNavigate to /select-languages/:language/:id
  React.useEffect(() => {
    navigate(`/listener/select-language/${language}/${id}`);
  }, [navigate, language, id]);

  return null; // No need to render anything, as it will just redirect
};

export const convertPCMToAudioBuffer = (pcmData) => {
  const bufferLength = pcmData.length * 2; // Int16Array uses 2 bytes per element
  const arrayBuffer = new ArrayBuffer(bufferLength);

  // Create a DataView to manipulate the buffer
  const dataView = new DataView(arrayBuffer);

  // Convert the PCM float values to Int16 values (range -32768 to 32767)
  for (let i = 0; i < pcmData.length; i++) {
    // Scale the float value to the Int16 range
    const int16Value = Math.max(-1, Math.min(1, pcmData[i])) * 32767;
    dataView.setInt16(i * 2, int16Value, true); // true for little-endian
  }

  // Now you have the ArrayBuffer with Int16Array data
  const int16Array = new Int16Array(arrayBuffer);
  return int16Array;
};

export const windowNavigate = (url) => {
  window.location.assign(url);
};

// Helper function to convert float32 audio data to Uint8Array
export const convertToUint8Array = (data) => {
  const int16Array = new Int16Array(data.length);
  for (let i = 0; i < data.length; i++) {
    const s = Math.max(-1, Math.min(1, data[i])); // Clamp value between -1 and 1
    int16Array[i] = s < 0 ? s * 0x8000 : s * 0x7fff;
  }
  return new Uint8Array(int16Array.buffer);
};

export const getStoredLanguage = () => {
  const storedLanguage = localStorage.getItem('i18nLanguage');
  return storedLanguage
}

export const storeLanguage = (lang) => {
  const storedLanguage = localStorage.setItem('i18nLanguage', lang);
  return storedLanguage
}