// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './i18n/eng.json';
import es from './i18n/spa.json';
import fr from './i18n/fra.json';
import deu from './i18n/deu.json';
import por from './i18n/por.json';
import cmn from './i18n/cmn.json';
import jpn from './i18n/jpn.json';
import hin from './i18n/hin.json';
import ita from './i18n/ita.json';
import kor from './i18n/kor.json';
import rus from './i18n/rus.json';
import tur from './i18n/tur.json';
import nld from './i18n/nld.json';
import pol from './i18n/pol.json';
import swe from './i18n/swe.json';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      eng: { translation: en },
      spa: { translation: es },
      fra: { translation: fr },
      deu: { translation: deu },
      por: { translation: por },
      cmn: { translation: cmn },
      jpn: { translation: jpn },
      hin: { translation: hin },
      ita: { translation: ita },
      kor: { translation: kor },
      rus: { translation: rus },
      tur: { translation: tur },
      nld: { translation: nld },
      pol: { translation: pol },
      swe: { translation: swe }
    },
    lng: "eng", // default language
    fallbackLng: "eng",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
