// theme.js
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: '"Manrope", "Arial", sans-serif',
    text: {
      fontSize: "18px",
      fontWeight: 500,
      color: "#FFFFFF"

    },
    "text-title": {
      fontSize: "24px",
      fontWeight: 500,
      color: "#FFFFFF"
    },
    'subtitle': {
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: 1
    }
  },
  palette: {
    background: {
      default: "#111111", // Custom background color
    },
    text: {
      primary: "#FFFFFF", // Custom text color
    },
    secondary: {
      main: "#1e1e1e",
    },
    gradient: {
      primary: "linear-gradient(90deg, #C999FF 0%, #F3E57A 100%)",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          background: "linear-gradient(90deg, #C999FF 0%, #F3E57A 100%)", // Gradient background for buttons
          color: "#000000", // Button text color
          "&:hover": {
            background: "linear-gradient(90deg, #C999FF 0%, #F3E57A 100%)", // Maintain gradient on hover
          },
          borderRadius: "8px",
          textTransform: "none",
          fontSize: "1.1rem",
          fontWeight: 500,
        },
      },
    },
  },
});

export default theme;
