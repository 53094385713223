import React , {useState, useEffect}from "react";
import { Backdrop, Typography, Box } from "@mui/material";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

import  Button  from "@commons/Button";
import { useTranslation } from "react-i18next";

const AllowMicrophoneAccessBackdrop = ({ open, setOpen = () => {} }) => {
const {t} = useTranslation();
  const [isMicBlocked, setIsMicBlocked] = useState(false);

  useEffect(() => {
    // Check if microphone permission is granted, denied, or prompt
    navigator.permissions.query({ name: 'microphone' }).then((permissionStatus) => {
      console.log('permissionStatus: ', permissionStatus);
      if (permissionStatus.state === 'denied' || permissionStatus.state === 'prompt') {
        setIsMicBlocked(true);  // Show the message if microphone access is blocked
        setOpen(true);          // Open the backdrop when blocked
      } else {
        setIsMicBlocked(false); // Hide the message if access is allowed or prompt
        setOpen(false);
      }
    });
  }, [setOpen]);

  const handleClose = () => {
    setOpen(false);
  };

  if (!isMicBlocked) return null;

  return (
    <Backdrop
      open={open}
      onClick={handleClose}
      style={{
        zIndex: 1200, // Higher than other elements
        backgroundColor: "rgba(0, 0, 0, 0.9)", // Dark transparent backdrop
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          gap: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box
            sx={{
              width: "48px",
              height: "48px",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgb(255,255,255,0.1)",
            }}
          >
            <ArrowOutwardIcon
              sx={{
                width: "24px",
                height: "24px",
                transform: "rotate(-90deg)",
              }}
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            gap: 1.5,
            flexDirection: "column",
          }}
        >
          <Typography variant="text-title">{t("allowMicText")}</Typography>
          <Typography sx = {{color: "#c7c7c7"}}>
          {t("allowMicSubText")}
          </Typography>
          <Button sx = {{width: "120px"}} onClick={handleClose}>{t("allowMicButton")}</Button>
        </Box>
      </Box>
    </Backdrop>
  );
};

export default AllowMicrophoneAccessBackdrop;
