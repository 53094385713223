import React, { useRef, useState } from "react";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { io } from "socket.io-client";
import PCMPlayer from "pcm-player";
import { useTranslation } from "react-i18next";

import Button from "@commons/Button";
import LanguageList from "@commons/LanguageList";
import { useToast } from "@commons/ToastProvider";
import ListenerWrapper from "@listener";
import {
  OUTPUT_SAMPLE_RATE,
  DEFAULT_OUTPUT_LANGUAGE,
  LANGUAGES,
} from "@utils/Constants";
import { windowNavigate, getStoredLanguage,storeLanguage } from "@helpers";
import Loader from "@commons/Loader";

function LanguageSelector() {
  const [code, selectedCode] = useState(
    getStoredLanguage() || DEFAULT_OUTPUT_LANGUAGE
  );
  const [loading, setLoading] = useState(false);
  const { id: socketId, language: speakerLanguage } = useParams();
  const showToast = useToast();
  const player = useRef(null);
  const socketRef = useRef(null);
  const changeRoom = useRef(null);
  const { t, i18n } = useTranslation();

  const connectSocket = () => {
    setLoading(true);
    socketRef.current = io(process.env.REACT_APP_SOCKET_URL, {
      path: "/ws/socket.io",
      transports: ["websocket"],
      query: {
        clientID: socketId,
      },
    });

    socketRef.current.on("connect", () => {
      console.log("Connected to WebSocket server");
      socketRef.current.emit("listener_joined", {
        language: DEFAULT_OUTPUT_LANGUAGE + "-" + code,
        client_id: socketId,
      });
      socketRef.current.on("listener_joined_speaker", (data) => {
        const isNotLanguage =
          LANGUAGES.filter((lang) => lang.code === data.language.split("-")[0])
            .length < 1;
        if (changeRoom.current && isNotLanguage) {
          setLoading(false);
          windowNavigate(
            "/listener/room/" +
              speakerLanguage +
              "-" +
              code +
              "/" +
              data.language
          );
          changeRoom.current = false;
        }
      });
    });
  };

  const handleLanguageChange = ({ target: { value } }) => {
    console.log(value);
    selectedCode(value);
  };

  const setupPlayerAudio = () => {
    player.current = new PCMPlayer({
      inputCodec: "Int16",
      channels: 1,
      sampleRate: OUTPUT_SAMPLE_RATE,
      flushTime: 0,
    });
  };

  const moveNext = () => {
    if (!code) {
      showToast(t("languageSelectToastText"));
      return;
    }
    setupPlayerAudio();

    if (code === DEFAULT_OUTPUT_LANGUAGE) {
      storeLanguage(DEFAULT_OUTPUT_LANGUAGE)
      i18n.changeLanguage(DEFAULT_OUTPUT_LANGUAGE);
      windowNavigate(`/listener/room/${speakerLanguage}-${code}/${socketId}`);
    } else {
      changeRoom.current = true;
      connectSocket();
    }
  };

  return (
    <ListenerWrapper title={t("listenerTitle")}>
      <LanguageList
        onChange={handleLanguageChange}
        selected={code}
        type="dropdown"
      />
      <Box mt="10px">
        <Button sx={{ width: "200px" }} onClick={moveNext}>
          {t("next")}
        </Button>
      </Box>
      <Loader open={loading} text={t("roomText")} />
    </ListenerWrapper>
  );
}

export default LanguageSelector;
