import React, { useState, createContext, useContext } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Typography } from "@mui/material";

// Create a context to manage the toasts
const ToastContext = createContext();

// Custom hook to use the Toast context
export const useToast = () => useContext(ToastContext);

// Reusable Toast Provider component
export const ToastProvider = ({ children }) => {
  const [toastState, setToastState] = useState({
    open: false,
    message: "",
    severity: "info", // Can be 'success', 'error', 'warning', 'info'
  });

  const showToast = (message, severity = "info") => {
    setToastState({ open: true, message, severity });
  };

  const hideToast = () => {
    setToastState({ ...toastState, open: false });
  };

  return (
    <ToastContext.Provider value={showToast}>
      {children}
      <Snackbar
        open={toastState.open}
        autoHideDuration={3000} // Hide after 3 seconds
        onClose={hideToast}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={hideToast}
          severity={toastState.severity}
          sx={{
            width: "100%",
            background: "linear-gradient(90deg, #C999FF 0%, #F3E57A 100%)",
          }}
        >
          <Typography>{toastState.message}</Typography>
        </MuiAlert>
      </Snackbar>
    </ToastContext.Provider>
  );
};
