import { getStoredLanguage } from "@helpers";

export const LANGUAGES = [
  { language: "English", code: "eng", flag: "gb" },
  { language: "Spanish", code: "spa", flag: "es" },
  { language: "French", code: "fra", flag: "fr" },
  { language: "German", code: "deu", flag: "de" },
  { language: "Portuguese", code: "por", flag: "pt" },
  { language: "Chinese", code: "cmn", flag: "cn" },
  { language: "Japanese", code: "jpn", flag: "jp" },
  { language: "Hindi", code: "hin", flag: "in" },
  { language: "Italian", code: "ita", flag: "it" },
  { language: "Korean", code: "kor", flag: "kr" },
  { language: "Russian", code: "rus", flag: "ru" },
  { language: "Turkish", code: "tur", flag: "tr" },
  { language: "Dutch", code: "nld", flag: "nl" },
  { language: "Polish", code: "pol", flag: "pl" },
  { language: "Swedish", code: "swe", flag: "se" },
];

export const INPUT_SAMPLE_RATE = 16000;
export const BUFFER_SIZE = INPUT_SAMPLE_RATE * 0.128; // New = 128ms, Old = 350ms
export const DEFAULT_AUDIO_VOLUME = 0.5;
export const OUTPUT_SAMPLE_RATE = 24000;
export const DEFAULT_OUTPUT_LANGUAGE = "spa";
export const DEFAULT_INPUT_LANGUAGE = getStoredLanguage() || "eng";
