import React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import share from "@assets/share.svg";

const ShareButton = ({ handleShare }) => {
  const { t } = useTranslation();
  return (
    <button
      style={{
        borderColor: "hsla(0,0%,100%,.15)",
        backgroundColor: "#111111",
        borderWidth: "1px",
        color: "#FFF",
        lineHeight: "1.25rem",
        borderRadius: "16px",
        height: "40px",
        padding: "0 10px",
        width: "auto",
        minWidth: "90px",
        cursor: "pointer"
      }}
      onClick={handleShare}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          gap: "5px",
        }}
      >
        <Box
          component="img"
          sx={{
            width: ".875rem",
            height: ".875rem",
          }}
          src={share}
          alt="share"
        />
        <Typography fontSize={".875rem"}>{t("share")}</Typography>
      </Box>
    </button>
  );
};

export default ShareButton;
