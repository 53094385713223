import React, { useState } from "react";
import { useParams } from "react-router-dom";

import SpeakerWapper from "@speaker";
import Button from "@commons/Button";

const SharingRoom = () => {
  const [title, setTitle] = useState(
    "Click button to share toby listening room"
  );
  const { language, id } = useParams();

  const handleShare = () => {
    try {
      navigator
        .share({
          url: `${window.location.origin}/listener/setup/${language}/${id}`,
        })
        .then(() => {
          setTitle("Thanks for sharing!");
        })
        .catch((err) => {
          console.log("err: ", err);
        });
    } catch (err) {
      console.log("err: ", err);
    }
  };

  return (
    <SpeakerWapper title={title} showMenu ={false}>
      <Button onClick={handleShare} sx={{ width: "100px" }}>
        Share
      </Button>
    </SpeakerWapper>
  );
};

export default SharingRoom;
