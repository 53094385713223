import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import SpeakerWapper from "@speaker";
import Button  from "@commons/Button";
import useAuth from '@hooks/useAuth';


function Login() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user, signInWithGoogle, loading: isLoading, } = useAuth();

  useEffect(() => {
    if (user?.email) {
      navigate("/speaker/select-language");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <SpeakerWapper title={t("loginTitle")} showMenu = {false}>
      <Button isLoading={isLoading} sx={{ width: "200px" }} onClick={signInWithGoogle}>
        {t("loginWithGoogle")}
      </Button>
    </SpeakerWapper>
  );
}

export default Login;
