// useInactivityTimer.js
import { useEffect } from "react";
import useAuth from "./useAuth";

const useInactivityTimer = (timeout) => {
  const { logout } = useAuth();
  useEffect(() => {
    let timer;

    const handleActivity = () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        logout();
        window.location.reload(); // Reload the page to redirect to login
      }, timeout);
    };

    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keypress", handleActivity);

    return () => {
      clearTimeout(timer);
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keypress", handleActivity);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeout]);
};

export default useInactivityTimer;
