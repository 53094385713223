import { Box, Typography } from "@mui/material";
import React from "react";
import MaleIcon from "@mui/icons-material/Male";
import FemaleIcon from "@mui/icons-material/Female";

import Select from "@commons/Select";
import { useTranslation } from "react-i18next";

function VoiceSelector({ voice = "male", onChange = () => {} }) {
  const {t} = useTranslation();

  return (
    <Box sx={{ width: "200px", marginBottom: "20px" }}>
      <Select
        value={voice}
        onChange={onChange}
        options={[
          {
            value: "male",
            label: (
              <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <MaleIcon height={16} width={24} />
                <Typography variant="text">{t("maleVoice")}</Typography>
              </Box>
            ),
          },
          {
            value: "female",
            label: (
              <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <FemaleIcon height={16} width={24} />
                <Typography variant="text">{t("femaleVoice")}</Typography>
              </Box>
            ),
          },
        ]}
      />
    </Box>
  );
}

export default VoiceSelector;
