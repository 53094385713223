import React from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Flag from "react-world-flags";
import { useTranslation } from "react-i18next";

import tick from "@assets/tick.svg";
import { LANGUAGES } from "@utils/Constants";
import Select from "@commons/Select";

const useStyles = makeStyles((theme) => ({
  languageBox: {
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.secondary.main, // Dark background
    padding: "8px 16px",
    borderRadius: "12px", // Rounded corners
    minWidth: "174px",
    cursor: "pointer",
    gap: "10px",
  },
}));

const LanguageList = ({
  onChange = () => {},
  selected = "",
  type = "dropdown",
  width,
}) => {
  const classes = useStyles();
  const { i18n } = useTranslation();

  const handleChange = (e) => {
    const lng = e.target.value
    i18n.changeLanguage(lng);
    localStorage.setItem('i18nLanguage', lng); // Store language preference
    onChange(e);
  };

  return (
    <Box display="flex" gap="5px" flexDirection={"column"}>
      {type === "dropdown" ? (
        <Box sx={{ width: width || "200px", marginBottom: "20px" }}>
          <Select
            value={selected}
            onChange={handleChange}
            options={LANGUAGES.map((val) => ({
              value: val.code,
              label: (
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <Flag code={val.flag} height={16} width={24} />
                  <Typography variant="text">{val.language}</Typography>
                </Box>
              ),
            }))}
          />
        </Box>
      ) : (
        LANGUAGES.map(({ code, language, flag }) => {
          const isSelected = selected === code;
          return (
            <Box
              className={classes.languageBox}
              onClick={() => {
                onChange(code);
              }}
              key={code}
            >
              <Box>
                <Flag code={flag} height={16} width={24} />
              </Box>
              <Box>
                <Typography variant="text">{language}</Typography>
              </Box>
              {!!isSelected && (
                <Box ml="auto">
                  <img src={tick} alt="tick" height={"16px"} width={"24px"} />
                </Box>
              )}
            </Box>
          );
        })
      )}
    </Box>
  );
};

export default LanguageList;
