import React, { useEffect, useMemo, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { io } from "socket.io-client";
import { useTranslation } from "react-i18next";

import SpeakerWapper from "@speaker";
import Button from "@commons/Button";
import useTimer from "@hooks/useTimer";
import {
  INPUT_SAMPLE_RATE,
  BUFFER_SIZE,
  DEFAULT_OUTPUT_LANGUAGE,
  LANGUAGES,
} from "@utils/Constants";
import copy from "@assets/copy.png";
import { copyText, generateUUID, convertToUint8Array } from "@helpers";
import { useToast } from "@commons/ToastProvider";
import Loader from "@commons/Loader";
import AllowMicrophoneAccess from "@commons/AllowMicrophoneAccess";
import useAuth from "@hooks/useAuth";
import FeedbackModal from "@commons/Feedback";
import QRCodeModal from "@commons/QR";

function Recorder() {
  const { time: timer, start: startTimer, stop: stopTimer } = useTimer(0);
  const [openQr, setOpenQr] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openFeedback, setOpenFeedback] = useState(false);
  const [openMicrophone, setOpenMicrophone] = useState(false);
  const { id, language, voice } = useParams();
  const socketRef = useRef({});
  const streamRef = useRef();
  const { user: user_data } = useAuth();
  const navigate = useNavigate();
  let audioBuffer = useRef([]);
  const { t } = useTranslation();

  const listenerURL = useMemo(() => {
    return window.location.origin + "/listener/setup/" + language + "/" + id;
  }, [language, id]);

  const sharingURL = useMemo(() => {
    return window.location.origin + "/speaker/share/" + language + "/" + id;
  }, [language, id]);

  const toast = useToast();
  useEffect(() => {
    startRecording();
    connectSocket(id);
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = ""; // Required for some browsers
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const connectSocket = (
    id,
    language = DEFAULT_OUTPUT_LANGUAGE,
    shareRoomIdWithNewListener,
    originalLanguage
  ) => {
    socketRef.current[language] = {
      socket: null,
      room: id,
      connected: false,
      language,
    };

    socketRef.current[language].socket = io(process.env.REACT_APP_SOCKET_URL, {
      path: "/ws/socket.io",
      transports: ["websocket"],
      query: {
        clientID: id,
      },
    });
    // socketRef.current.connect();

    socketRef.current[language].socket.on("connect", () => {
      console.log("Connected to WebSocket server");
      setLoading(false);
      startTimer();
      socketRef.current[language].connected = true;
      const glossary = localStorage.getItem("glossary");
      if (glossary) {
        socketRef.current[language].socket.emit(
          "glossary",
          JSON.parse(glossary)
        );
      }
      updateLanguages(language);
      if (shareRoomIdWithNewListener) {
        //emit room Id to the listener
        socketRef.current[originalLanguage].socket.emit("listener_joined", {
          language: id,
          client_id: shareRoomIdWithNewListener,
        });
      }
      socketRef.current[language].socket.on("translation_text", (data) => {
        const text = data.payload || data;
        console.log("text: ", text);
      });
      socketRef.current[language].socket.on(
        "listener_joined_speaker",
        ({ language: listenerLanguages }) => {
          const listenerLanguage = listenerLanguages.split("-")[1];
          const originalLanguage = listenerLanguages.split("-")[0];
          console.log("originalLanguage: ", originalLanguage);
          console.log("new language: ", listenerLanguage);
          if (socketRef.current[listenerLanguage]) {
            const roomID = socketRef.current[listenerLanguage].room;
            console.log("exising roomID: ", roomID);
            //emit the roomID to the listener on current room
            socketRef.current[originalLanguage].socket.emit("listener_joined", {
              language: roomID,
              client_id: socketRef.current[originalLanguage].room,
            });
          } else {
            const newRoomId = generateUUID();
            const isLanguage =
              LANGUAGES.filter((lang) => lang.code === listenerLanguage)
                .length > 0;
            if (isLanguage) {
              socketRef.current[listenerLanguage] = {
                socket: null,
                room: newRoomId,
                connected: false,
              };
              console.log("created newRoomId: ", newRoomId);
              connectSocket(
                newRoomId,
                listenerLanguage,
                socketRef.current[language].room,
                originalLanguage
              );
            }
          }
        }
      );
      // socketRef.current[language].socket.on("new_room", (data) => {
      //   console.log("new room data:", data);
      // });
    });

    socketRef.current[language].socket.on("connect_error", (error) => {
      console.log("Connection error:", error);
    });
  };

  // Request audio access and start recording
  const startRecording = async () => {
    try {
      setOpenMicrophone(true);
      setLoading(true);
      navigator.mediaDevices
        .getUserMedia({
          audio: { echoCancellation: true, noiseSuppression: true },
        })
        .then((stream) => {
          streamRef.current = stream;
          const audioContext = new (window.AudioContext ||
            window.webkitAudioContext)({ sampleRate: INPUT_SAMPLE_RATE });
          const source = audioContext.createMediaStreamSource(stream);
          const processor = audioContext.createScriptProcessor(1024, 1, 1);
          const analyser = audioContext.createAnalyser();
          source.connect(processor);
          processor.connect(audioContext.destination);
          source.connect(analyser);

          processor.onaudioprocess = function (e) {
            const array = new Uint8Array(analyser.frequencyBinCount);
            analyser.getByteFrequencyData(array);

            const inputData = e.inputBuffer.getChannelData(0);
            audioBuffer.current = audioBuffer.current.concat(
              Array.from(inputData)
            );
            if (audioBuffer.current.length >= BUFFER_SIZE) {
              const audioDataToSend = convertToUint8Array(
                audioBuffer.current.slice(0, BUFFER_SIZE)
              );
              audioBuffer.current = audioBuffer.current.slice(BUFFER_SIZE); // Trim the buffer
              sendAudioToAllRooms(audioDataToSend, inputData);
            }
          };
          // const mediaRecorder = new MediaRecorder(stream);

          // // Function to handle stopping, downloading, and restarting recording
          // const handleRecordingCycle = () => {
          //   // Array to store the recorded audio data
          //   const audioChunks = [];

          //   // Event listener to push audio data chunks when data is available
          //   mediaRecorder.ondataavailable = (event) => {
          //     audioChunks.push(event.data);
          //   };

          //   // When recording stops, create a downloadable audio file
          //   mediaRecorder.onstop = () => {
          //     Object.keys(socketRef.current).forEach((language) => {
          //       const name = `${language}_recorded_audio_for_room_${socketRef.current[language].room}.wav`;
          //       const audioBlob = new Blob(audioChunks, { type: "audio/wav" });

          //       // Create a URL for the audio blob
          //       const audioUrl = URL.createObjectURL(audioBlob);

          //       // Create a temporary anchor element and trigger a download
          //       const link = document.createElement("a");
          //       link.href = audioUrl;
          //       link.download = name;
          //       document.body.appendChild(link);
          //       link.click();
          //       document.body.removeChild(link);
          //       console.log(
          //         "Audio recording downloaded and new recording started."
          //       );
          //     });
          //     // Create a blob from the audio chunks

          //     // Restart the recording process after the download
          //     startRecording();
          //   };

          //   // Start recording
          //   mediaRecorder.start();
          //   console.log("Recording started.");

          //   // Stop recording after 50 seconds and trigger download
          //   setTimeout(() => {
          //     mediaRecorder.stop();
          //   }, 20000); // 50000 ms = 50 seconds
          // };

          // // Function to start recording and schedule the next cycle
          // const startRecording = () => {
          //   handleRecordingCycle();
          // };

          // // Start the first recording cycle
          // startRecording();
        })
        .catch((err) => console.log(err, "from stream"))
        .finally(() => {
          setOpenMicrophone(false);
        });
    } catch (error) {
      console.error("Error accessing audio devices.", error);
    }
  };

  // Function to send audio data to the server
  function sendAudioToServer(audioData, language, inputData) {
    const socket = socketRef.current[language]?.socket;
    if (!socket || !socket.connected) return;

    try {
      socket.emit("incoming_audio", { channel: 0, audioData }, (response) => {
        // console.log("Server response:", audioData);
      });
    } catch (error) {
      console.error("Error sending audio data:", error);
    }
  }

  const sendAudioToAllRooms = (audioData, inputData) => {
    const openRooms = [...Object.keys(socketRef.current)];
    openRooms.forEach((language) => {
      sendAudioToServer(audioData, language, inputData);
    });
  };

  function updateLanguages(outputLang = DEFAULT_OUTPUT_LANGUAGE) {
    const inputLang = language;

    const languages = [inputLang, outputLang];
    const config = {
      async_processing: true,
      buffer_limit: 1,
      channels: [0], //1
      client_id: id,
      debug: false,
      model_name: "Chungus",
      model_type: "big",
      rate: INPUT_SAMPLE_RATE,
      voice_type: voice,
      user_data,
    };
    socketRef.current[outputLang].socket.emit(
      "update_languages",
      languages,
      config
    );
  }

  const handleOpenFeedback = () => {
    stopTimer();
    setOpenFeedback(true);
  };

  const handleFeedBack = (rating) => {
    stopRecording(rating);
  };

  const stopRecording = (rating) => {
    setOpenFeedback(false);
    Object.keys(socketRef.current).forEach(async (language, i) => {
      const socket = socketRef.current[language]?.socket;
      console.log("socket: ", socket);
      if (socket) {
        if (rating && i === 0) {
          await socket?.emit("incoming_analytics", {
            feedbacks: [rating],
          });
        }
        socket?.disconnect();
      }
    });
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
    }
    navigate("/speaker/select-language");
  };

  const handleCloseQr = () => {
    setOpenQr(false);
  };

  const handleOpenQr = () => {
    setOpenQr(true);
  };

  return (
    <SpeakerWapper
      title={!loading && t("adminPortalText")}
      share={true}
      handleShare={handleOpenQr}
    >
      <Box>
        <Typography variant="text-title">{timer}</Typography>
      </Box>
      <Box mt="20px">
        <Button sx={{ width: "200px" }} onClick={handleOpenFeedback}>
          {t("stopButton")}
        </Button>
      </Box>
      <Box
        mt="40px"
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          background: "#1e1e1e",
          width: { xs: "85%", sm: "350px" },
          height: "25px",
          borderRadius: "12px",
          padding: "10px 20px",
          cursor: "pointer",
        }}
        onClick={() => copyText(listenerURL, toast)}
      >
        <Typography
          variant="text"
          sx={{
            width: "85%",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {listenerURL}
        </Typography>
        <img
          src={copy}
          alt="copy"
          height={"30px"}
          width="30px"
          style={{ marginLeft: "auto" }}
        />
      </Box>
      <Loader open={loading} text={t("roomText")} />
      <AllowMicrophoneAccess
        open={openMicrophone}
        setOpen={setOpenMicrophone}
      />
      <FeedbackModal
        open={openFeedback}
        handleClose={stopRecording}
        handleFeedBack={handleFeedBack}
      />
      <QRCodeModal open={openQr} handleClose={handleCloseQr} url={sharingURL} />
    </SpeakerWapper>
  );
}

export default Recorder;
