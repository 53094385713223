import { MenuItem, Select as MuiSelect } from "@mui/material";
import React from "react";

const menuProps = {
  PaperProps: {
    style: {
      backgroundColor: "#1e1e1e", // Dark background for the dropdown
      color: "#fff", // White text color for the dropdown
      fontWeight: 500,
      fontSize: "18px",
    },
  },
};

function Select({ value, onChange = () => {}, options = [] }) {
  return (
    <MuiSelect
      value={value}
      onChange={onChange}
      sx={{
        mt: 1,
        bgcolor: "#1e1e1e",
        color: "#fff",
        width: "100%",
        height: "45px",
        fontWeight: 500,
        fontSize: "20px",
        borderRadius: "12px",
        ".MuiSelect-icon": { color: "#fff" }, // Arrow color
      }}
      MenuProps={menuProps} // Apply custom dropdown styles
    >
      {options.map(({ value, label }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </MuiSelect>
  );
}

export default Select;
