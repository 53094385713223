// useAuth.js
import { useEffect, useState } from "react";
import { onAuthStateChanged, signInWithPopup, signOut } from "firebase/auth";
import { auth, provider } from "@utils/Firebase";

const useAuth = () => {
  const [user, setUser] = useState(null);

  const [loading, setLoading] = useState(true); // For showing a loader while fetching user

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
        if (currentUser) {
            setUser(extractData(currentUser)); // Set the user data if logged in
        } else {
        setUser(null); // User is logged out, set user to null
      }
      setLoading(false); // Stop loading once we know the auth state
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const extractData = (user) => {
    const { photoURL, displayName, email } = user;
    return { photo: photoURL, name: displayName, email };
  };

  // Sign in with Google
  const signInWithGoogle = async () => {
    try {
      setLoading(true);
      const result = await signInWithPopup(auth, provider);
      setUser(extractData(result.user)); // Store the user data
    } catch (error) {
      console.error("Error during sign-in", error);
    } finally {
      setLoading(false); // Stop loading once we know the auth state
    }
  };

  // Sign out
  const logout = async () => {
    try {
      await signOut(auth);
      setUser(null); // Clear user data
    } catch (error) {
      console.error("Error during sign-out", error);
    }
  };

  return { user, signInWithGoogle, logout, loading };
};

export default useAuth;
