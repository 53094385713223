import { Backdrop, Typography } from "@mui/material";
import React from "react";
import logo from "@assets/spinning-logo.svg"
function Loader({
  open,
  handleClose,
  text = "",
}) {
  return (
    <Backdrop
      sx={{
        color: "#fff",
        display: "flex",
        alignItems: "center",
        gap: "10px",
        flexDirection: "column",
        opacity: 1
      }}
      open={open}
      onClick={handleClose}
    >
      <img src = {logo} alt = "spinner-logo" height={"70px"} width={"70px"} className="App-logo"/>
      {text && <Typography sx={{ width: "90%" }} variant="text">{text} </Typography>}
    </Backdrop>
  );
}

export default Loader;
