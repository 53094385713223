import { Box, Typography } from "@mui/material";
import React from "react";

import logo from "@assets/logo.svg";
import SpeakerMenu from "@commons/Menu";

function SpeakerWapper({
  children,
  title = "Welcome to toby ai",
  showMenu = true,
  share = false,
  handleShare = () => {},
}) {
  return (
    <>
      {!!showMenu && <SpeakerMenu share={share} handleShare={handleShare} />}
      <Box mt="70px">
        <img height={"150px"} width={"150px"} src={logo} alt="logo" />
      </Box>
      <Box mt="30px">
        <Typography variant="title" sx={{ fontSize: "30px" }}>
          {title}
        </Typography>
      </Box>
      <Box
        mt="30px"
        sx={{
          width: {
            xs: "80%",
          },
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        {children}
      </Box>
    </>
  );
}

export default SpeakerWapper;
